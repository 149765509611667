<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery" style="margin-bottom: 30px">
      <a-row :gutter="12">
        <a-col :span="8">
          <a-form-item label="规则名称">
            <a-select ref="select" style="width: 200px" @change="handleChange" allowClear>
              <a-select-option v-for="item in ruleTemplate" :value="item.itemValue" :key="item.itemValue">{{ item.itemText }} </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
            <a-button type="primary" @click="() => {queryParams.pageNum = 1; searchQuery()}" icon="search">查询</a-button>
            <a-button type="primary" style="margin-left: 8px" @click="createRule">新增</a-button>
          </span>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :scroll="{ x: 1000 }"
      :pagination="false"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)"> 编辑 </a>
      </span>
      >
      <span slot="status" slot-scope="text, record">
        {{ record.status == 1 ? '启用' : record.status == 2 ? '禁用' : '无状态' }}
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right">
      <v-pagination
        v-if="total"
        :page-num="queryParams.pageNum"
        :page-size="queryParams.pageSize"
        :total="total"
        @change="handleTableChange"
      />
    </div>
    <a-modal :visible="modalvisible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
      <a-form :form="form2">
        <a-form-item label="规则名称">
          <a-select
            ref="select"
            style="width: 120px"
            @change="onRuleCodeChange"
            v-decorator="['ruleCode', { rules: [{ required: true, message: '请选择规则名称!' }] }]"
          >
            <a-select-option v-for="item in ruleTemplate" :value="item.itemValue" :key="item.itemValue">{{ item.itemText }} </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="表达式">
          <a-textarea
            v-decorator="['express', { rules: [{ required: true, message: '请输入表达式' }] }]"
            placeholder="表达式"
            @change="onExpressChange"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
        <a-form-item label="状态">
          <a-select v-model="modalForm.status">
            <a-select-option v-for="item in status" :key="item.itemValue" :value="item.itemValue">
              {{ item.itemText }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getRulesPages, addNewRule, updateRule } from '../../../api/risk'
import { findDictItemByCode } from '@/api/pay'
import { message } from 'ant-design-vue/es'

export default {
  name: 'RuleTemplate',
  data() {
    return {
      queryParams: {
        ruleName: '', // 规则名称
        // ruleCode: '',
        pageNum: 1,
        pageSize: 10,
      },
      ruleTemplate: [],
      dataSource: [],
      loading: false,
      total: 0,
      columns: [
        {
          title: '规则编码',
          dataIndex: 'ruleCode',
        },
        {
          title: '规则名称',
          dataIndex: 'ruleName',
        },
        {
          title: '规则表达式',
          dataIndex: 'express',
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      edituserId: '',
      status: [
        {
          itemValue: '1',
          itemText: '启用',
        },
        {
          itemValue: '2',
          itemText: '禁用',
        },
      ],
      confirmLoading: false,
      modalvisible: false,
      edit: false,
      editRuleId: '',
      modalForm: {
        ruleCode: '',
        express: '',
        ruleName: '',
        status: '1',
      },
      form2: this.$form.createForm(this, { name: 'coordinated' }),
    }
  },
  computed: {
    ...mapGetters(['userNo', 'userId']),
  },
  created() {
    findDictItemByCode('ruleTemplate').then((res) => {
        this.ruleTemplate = res.data.ruletemplate
      })
  },
  methods: {
    getRuleName(ruleCode) {
      const res = this.ruleTemplate.find((item) => item.itemValue == ruleCode)
      return res ? res.itemText : ''
    },
    onRuleCodeChange(ruleCode) {
      console.log('cocdddd' + ruleCode)
      this.form2.setFieldsValue({
        ruleCode,
      })
    },
    onExpressChange(express) {
      this.form2.setFieldsValue({ express: express.target.value })
    },
    handleChange(value) {
      this.queryParams.ruleName = this.getRuleName(value)
    },
    handleTableChange(pageNum, pageSize) {
      this.queryParams.pageNum = pageNum
      this.queryParams.pageSize = pageSize
      this.searchQuery()
    },
    handleEdit(row) {
      this.modalvisible = true
      this.edit = true
      this.editRuleId = row.id
      this.modalForm.status = row.status.toString()

      this.$nextTick(() => {
        this.form2.setFieldsValue({
          ruleCode: row.ruleCode,
          express: row.express,
        })
      })
    },
    createRule() {
      this.modalvisible = true
    },
    handleOk() {
      this.confirmLoading = true
      if (this.edit) {
        const fieldsValue = this.form2.getFieldsValue()
        console.log(fieldsValue)

        let data = {
          ...fieldsValue,
          id: this.editRuleId,
          // ruleName: typeObj[fieldsValue.ruleCode],
          ruleName: this.getRuleName(fieldsValue.ruleCode),
          status: this.modalForm.status,
          userId: this.userId,
        }

        updateRule(data).then((res) => {
          this.confirmLoading = false
          if (res.code == '2000') {
            this.modalvisible = false
            message.success('编辑成功')
            this.searchQuery()
          }
        })
      } else {
        this.form2.validateFields((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values)
          }
        })
        const ruleCode = this.form2.getFieldValue('ruleCode')

        addNewRule({
          ...this.form2.getFieldsValue(),
          status: +this.modalForm.status,
          ruleName:  this.getRuleName(ruleCode),
        }).then((res) => {
          this.confirmLoading = false
          if (res.code == '2000') {
            this.modalvisible = false
            message.success('保存成功')
            this.searchQuery()
          }
        })
      }

      this.edit = false
    },
    handleCancel() {
      this.modalvisible = false
      this.edit = false
    },
    searchQuery() {
      this.loading = true
      getRulesPages(this.queryParams).then((res) => {
        this.dataSource = res.data.list
        this.total = res.data.page.total
        this.confirmLoading = false
        this.loading = false
      })
    },
  },
}
</script>
<style scoped lang="less">
.appContainer ::v-deep {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
/deep/.ant-form-item {
  margin-bottom: 14px;
}
</style>
